<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" sm="62" lg="56">
        <BaseCard heading="Filter">
        <v-row>
        <v-col :cols="2">
          <v-text-field 
            ref="ukeyword"
            v-model="ukeyword"
            label="Username"
            placeholder="Keyword"
          ></v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-text-field
            ref="mkeyword"
            v-model="mkeyword"
            label="Module"
            placeholder="Keyword"
          ></v-text-field>
          </v-col>
          <v-col :cols="2">
          <v-text-field
            ref="akeyword"
            v-model="akeyword"
            label="Action"
            placeholder="Keyword"
          ></v-text-field>
          </v-col>
          <v-col :cols="2">
          <v-text-field
            ref="aukeyword"
            v-model="aukeyword"
            label="Url"
            placeholder="Keyword"
          ></v-text-field>
          </v-col>
          <v-col :cols="2">
          <v-text-field
            ref="skeyword"
            v-model="skeyword"
            label="Status"
            placeholder="Keyword" 
          ></v-text-field>
          </v-col>
        </v-row>
          <v-card-actions>
            <v-btn text @click="clearfilter">Clear</v-btn>
            <v-spacer></v-spacer>
            <v-btn color="#21C1D6" text @click="filterLogs">Search</v-btn>
          </v-card-actions>
        </BaseCard>
         
      </v-col>
    </v-row>

    <v-card class="col-md-12">
      <v-col class="justify-end align-center d-flex pa-7 mb-1 mt-1 pt-5">
        <v-col>
        <v-row class="justify-end">
        <v-btn
          v-if="!this.isFullDataExported"
          class="btn btn-primary mr-2"
          color="#21C1D6"
          style="color: white; text-transform: none"
          @click="getAllFiltLogs"
        >
          Export
        </v-btn>
        </v-row>
        
          <v-row class="justify-end">
            <v-btn class="mt-1" color="white" v-if="this.isFullDataExported">
        <downloadexcel
          v-if="this.isFullDataExported"
          class="btn btn-primary mr-2"
          :data="this.allfilt_logs"
          :fields="exported_fields"
          name="user_logs.xls"
          style="color: #21c1d6; text-transform: none; cursor: pointer"
        >
          Download Excel
        </downloadexcel>
            </v-btn>
        </v-row>
        <v-row class="justify-end">
          <v-btn class="mt-1" color="white" v-if="this.isFullDataExported">
        <download-csv
          v-if="this.isFullDataExported"
          class="btn btn-primary mr-2"
          :data="this.allfilt_logs"
          name="user_logs.csv"
          style="color: #21c1d6; text-transform: none; cursor: pointer"
        >
          Download CSV
        </download-csv>
          </v-btn>
        </v-row>
        </v-col>
      </v-col>
    </v-card>

    <v-row>
      <v-col cols="12" sm="12">
        <v-data-table
          :headers="headers"
          :items="logs"
          :options.sync="options"
          :server-items-length="totalDesserts"
          :loading="loading"
          class="elevation-1"
          disable-pagination
          :hide-default-footer="true"
        ></v-data-table>
      </v-col>
      <div style="padding: 25px">
        <v-row>
          <v-col cols="12" sm="3">
            <v-select
              v-model="pageSize"
              :items="pageSizes"
              label="Items per Page"
              @change="handlePageSizeChange"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="9">
            <v-pagination
              :length="totalPages"
              total-visible="10"
              @input="handlePageChange"
              v-model="page"
              color="#21C1D6"
            >
            </v-pagination>
          </v-col>
        </v-row>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import downloadexcel from "vue-json-excel";

export default {
  components: {
    downloadexcel,
  },
  data() {
    return {
      totalDesserts: 0,
      desserts: [],
      loading: true,
      options: {},
      page: 1,
      logs: [],
      allfilt_logs: [],
      isFullDataExported: false,
      totalPages: 0,
      pageSize: 25,
      pageSizes: [25, 50, 100, "ALL"],
      totalItems: 0,
      ukeyword: "",
      mkeyword: "",
      akeyword: "",
      aukeyword: "",
      skeyword: "",
      headers: [
        { text: "Module", value: "module_name" },
        { text: "Action", value: "user_action" },
        { text: "Action URL", value: "action_url" },
        { text: "Status", value: "action_status" },
        { text: "User name", value: "user_name" },
        { text: "Happened on", value: "statuschanged_time" },
      ],
       exported_fields: {
        "Module": "module_name", "Action": "user_action", "Action URL": "action_url",
        "Status": "action_status", "User name": "user_name", "Happened on": "statuschanged_time"},
    };
  },
  watch: {
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
    ukeyword:{
      handler(){
        this.filterLogs();
      },
      deep: true,
    },
    mkeyword:{
      handler(){
        this.filterLogs();
      },
      deep: true,
    },
    akeyword:{
      handler(){
        this.filterLogs();
      },
      deep: true,
    },
    aukeyword:{
      handler(){
        this.filterLogs();
      },
      deep: true,
    },
    skeyword:{
      handler(){
        this.filterLogs();
      },
      deep: true,
    }
  },
  mounted() {
    this.getLogs();
  },
  methods: {
    getRequestParams(searchTitle, page, pageSize) {
      let params = {};

      if (searchTitle) {
        params["title"] = searchTitle;
      }

      if (page) {
        params["pageNo"] = page;
      }

      if (pageSize) {
        if (pageSize == "ALL") {
          params["size"] = this.totalLogs;
        } else {
          params["size"] = pageSize;
        }
      }
      if (this.ukeyword != "") {
        params["ukeyword"] = this.ukeyword;
      }
      if (this.mkeyword != "") {
        params["mkeyword"] = this.mkeyword;
      }
      if (this.akeyword != "") {
        params["akeyword"] = this.akeyword;
      }
      if (this.aukeyword != "") {
        params["aukeyword"] = this.aukeyword;
      }
      if (this.skeyword != "") {
        params["skeyword"] = this.skeyword;
      }
      return params;
    },
    async getLogs() {
      const params = this.getRequestParams("", this.page, this.pageSize, this.ukeyword,this.mkeyword,this.akeyword,this.aukeyword,this.skeyword);
      console.log(params);
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      await axios
        .get("http://logbkend.preferati.net/api/getAlluserlogs", { params: params })
        .then(async (logs) => {
          console.log(logs);
          this.logs = await logs.data.logs;
          this.totalPages = await logs.data.totalPages;
          this.totalItems = await logs.data.totalItems;
          this.loading = false;
          return logs.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
        async getAllFiltLogs() {
      const params = this.getRequestParams("", 0, 0);
      await axios
        .get("http://logbkend.preferati.net/api/getAlluserlogs", { params: params })
        .then(async (logs) => {
          this.allfilt_logs = await logs.data.logs;
          //this.totalPages = await candidates.data.totalPages;
          this.loading = false;
          this.isFullDataExported = true;
          return logs.data;
        })
        .catch((err) => {
          this.isFullDataExported = false;
          console.log("getAllFiltLogs() Err :" + err);
        });
    },
    handlePageSizeChange(size) {
      this.pageSize = size;
      this.page = 1;
      this.getLogs();
    },
    handlePageChange(value) {
      this.page = value;
      //  console.log(value);
      this.getLogs();
    },
    filterLogs(text) {
       this.page = 1;
      this.getLogs();
      this.isFullDataExported = false;
      const matchExists = text
        .toRegExp()
        .includes(this.ukeyword.toRegExp())
        .includes(this.mkeyword.toRegExp())
        .includes(this.akeyword.toRegExp())
        .includes(this.aukeyword.toRegExp())
        .includes(this.skeyword.toRegExp())
      if (!matchExists) return text;

      const re = new RegExp(this.ukeyword,this.mkeyword,this.akeyword,this.aukeyword,this.skeyword, "i");
      return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);
     
    },

//  highlightMatches(text) {
//       const matchExists = text
//         .toLowerCase()
//         .includes(this.ckeyword.toLowerCase());
//       if (!matchExists) return text;

//       const re = new RegExp(this.ckeyword, "i");
//       return text.replace(re, matchedText => `<strong>${matchedText}</strong>`);
//     },
            // v-for="(logs, index) in filteredLogs" :key="`user_name-${index}`" 
            // v-html="filterLogs(logs.module_name)"
            // :v-html="filterLogs([...logs.user_name].sort().join(', '))"

    clearfilter() {
      this.page = 1;
      this.ukeyword = "";
      this.mkeyword = "";
      this.akeyword = "";
      this.aukeyword = "";
      this.skeyword = "";
      this.getLogs();
      this.isFullDataExported = false;
    },
    getDataFromApi() {
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = data.items;
        this.totalDesserts = data.total;
        this.loading = false;
      });
    },
    /**
     * In a real application this would be a call to fetch() or axios.get()
     */
    fakeApiCall() {
      return new Promise((resolve) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        let items = this.getDesserts();
        const total = items.length;

        if (sortBy.length === 1 && sortDesc.length === 1) {
          items = items.sort((a, b) => {
            const sortA = a[sortBy[0]];
            const sortB = b[sortBy[0]];

            if (sortDesc[0]) {
              if (sortA < sortB) return 1;
              if (sortA > sortB) return -1;
              return 0;
            } else {
              if (sortA < sortB) return -1;
              if (sortA > sortB) return 1;
              return 0;
            }
          });
        }

        if (itemsPerPage > 0) {
          items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
        }

        setTimeout(() => {
          resolve({
            items,
            total,
          });
        }, 1000);
      });
    },
    getDesserts() {
      return [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%",
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: "1%",
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: "7%",
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: "8%",
        },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: "16%",
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: "0%",
        },
        {
          name: "Lollipop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: "2%",
        },
        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: "45%",
        },
        {
          name: "Donut",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: "22%",
        },
        {
          name: "KitKat",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: "6%",
        },
      ];
    },
  },
  // computed: {
  //   filteredLogs() {
  //     return this.logs.filter(logs => {
  //       const module_name = logs.module_name.toString().toRegExp();
  //       const user_action = logs.user_action.toRegExp();
  //       const action_url = logs.action_url.toRegExp();
  //       const action_status = logs.action_status.toRegExp();
  //       const user_name = logs.user_name.toRegExp();
  //       const statuschanged_time = logs.statuschanged_time.toRegExp();
  //       const searchTerm = this.logs.ckeyword.toRegExp();

  //       return (
  //         module_name.includes(searchTerm) || user_action.includes(searchTerm) ||
  //         action_url.includes(searchTerm) || action_status.includes(searchTerm) ||
  //         user_name.includes(searchTerm) || statuschanged_time.includes(searchTerm)
  //       );
  //     });
  //   }
  // }
};
</script>
